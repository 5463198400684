const ArrowIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 12.5L13 1.5" stroke="white" strokeWidth="3" strokeLinejoin="round" />
      <path d="M3 1.5H13V11.5" stroke="white" strokeWidth="3" strokeLinecap="square" />
    </svg>
  );
};

export default ArrowIcon;
