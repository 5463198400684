const SkilliumClockIcon = () => (
  <svg
    className="skilliumIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none">
    <path d="M9.70836 6.18742V9.62492H5.58336V12.3749H12.4584V6.18742H9.70836Z" fill="#191919" />
    <path
      d="M18.1206 13.7499C17.0179 16.5625 14.2823 18.5624 11.0834 18.5624C7.88442 18.5624 5.14955 16.5625 4.04611 13.7499H1.15449C2.36174 18.1039 6.34992 21.3124 11.0834 21.3124C15.8168 21.3124 19.805 18.1039 21.0122 13.7499H18.1206Z"
      fill="#191919"
    />
    <path
      d="M3.65217 12.3749C3.56967 11.928 3.52086 11.4702 3.52086 10.9999C3.52086 6.83023 6.91367 3.43742 11.0834 3.43742C15.253 3.43742 18.6459 6.83023 18.6459 10.9999C18.6459 11.4702 18.597 11.928 18.5145 12.3749H21.2934C21.3532 11.9239 21.3959 11.4674 21.3959 10.9999C21.3959 5.3136 16.7697 0.687416 11.0834 0.687416C5.39705 0.687416 0.770859 5.3136 0.770859 10.9999C0.770859 11.4674 0.813484 11.9239 0.873296 12.3749H3.65217Z"
      fill="#191919"
    />
  </svg>
);

export default SkilliumClockIcon;
