import { useState, useEffect, useContext } from 'react';
import styles from './ThankYou.module.scss';
import LogoIcon from '../../components/Icons/LogoIcon';
import ButtonError from '../../components/UI/ButtonError';
import ArrowIcon from '../../components/Icons/ArrowIcon';
import { GlobalContext } from '../../context/GlobalContext';
import skilliumLogo from '../../assets/images/skillium-logo.png';

const ThankYou = () => {
  const [type, setType] = useState(1);
  const [redirectUri, setRedirectUri] = useState(null);

  const { translate, environmentConfig } = useContext(GlobalContext);

  const params = new URL(document.location).searchParams;

  const handlerClickBtn = () => {
    if (redirectUri) {
      window.location.href = redirectUri;
    } else if (process.env.REACT_APP_DESIGN === 'Skillium') {
      window.location.href = 'https://skillium.org/';
    } else {
      window.location.href = environmentConfig.URL_ABOUT_EVLTR;
    }
  };

  const isCallNow = params.get('from') === 'call-now';

  useEffect(() => {
    if (params.get('redirectUri')) {
      let url;
      url = decodeURIComponent(params.get('redirectUri'));
      if (params.get('theme')) {
        url += `&theme=${params.get('theme')}`;
      }
      setRedirectUri(url);
    } else if (!isCallNow) {
      setType(2);
    }
  }, [params, isCallNow]);

  return (
    <div className={styles.thankYou}>
      <div className={styles.thankYou__logo}>
        <LogoIcon />
      </div>
      <div className={`${styles.thankYou__info} ${type === 1 && styles.thankYou__notSlots}`}>
        <img src={skilliumLogo} alt="Logo" className={styles.thankYou__info__skilliumLogo} />
        <h2>Thanks!</h2>
        <p>Registration confirmed</p>
        {isCallNow ? (
          <span>We will call you in 5 minutes.</span>
        ) : (
          <>
            <span>We will get in touch with you soon.</span>
            {type === 1 && <span>{translate.DESCRIPTION_IN_THANKS_PAGE}</span>}
          </>
        )}

        <div className={styles.thankYou__info__btn}>
          <ButtonError onClick={handlerClickBtn}>
            <>
              Continue <ArrowIcon />
            </>
          </ButtonError>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
