import styles from './ButtonError.module.scss';
import Loader from './Loader';

const ButtonError = ({ onClick, children, type, isDisabled, isLoading }) => {
  return (
    <button
      type="button"
      className={`${styles.buttonError} ${styles[type]} ${isLoading ? styles.loading : ''}`}
      disabled={isDisabled || isLoading}
      onClick={onClick}>
      {isLoading ? <Loader /> : children}
    </button>
  );
};

export default ButtonError;
