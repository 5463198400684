const SkilliumPhoneIcon = () => (
  <svg
    className="skilliumIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none">
    <g clipPath="url(#clip0_2586_2097)">
      <path
        d="M7.64592 2.729V8.9165H14.5209V5.479H15.8959V19.9165H6.27092V0.666505H3.52092V22.6665H18.6459V2.729H7.64592Z"
        fill="#191919"
      />
      <path d="M10.3959 10.979H7.64592V13.729H10.3959V10.979Z" fill="#191919" />
      <path d="M14.5209 10.979H11.7709V13.729H14.5209V10.979Z" fill="#191919" />
      <path d="M10.3959 15.104H7.64592V17.854H10.3959V15.104Z" fill="#191919" />
      <path d="M14.5209 15.104H11.7709V17.854H14.5209V15.104Z" fill="#191919" />
    </g>
    <defs>
      <clipPath id="clip0_2586_2097">
        <rect width="22" height="22" fill="white" transform="translate(0.0833359 0.666668)" />
      </clipPath>
    </defs>
  </svg>
);

export default SkilliumPhoneIcon;
