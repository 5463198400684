import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { postBookingAPI, postBookingRescheduleAPI, postCallMeBackAPI } from '../../api/api';
import BookMeetingDateList from '../../components/BookMeeting/BookMeetingDateList';
import BookMeetingSelect from '../../components/BookMeeting/BookMeetingDropdown';
import BookMeetingTimeList from '../../components/BookMeeting/BookMeetingTimeList';
import convertTimeZones from '../../components/utils/timezones';
import { GlobalContext } from '../../context/GlobalContext';
import styles from './BookMeeting.module.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import {
  TIMEZONE_ASIA,
  TIMEZONE_EUROPE,
  TIMEZONE_OCEANIA,
  TIMEZONE_US_CANADA
} from '../../constants/timezone';
import { objectToUrl, urlToObject } from '../../components/utils/url';
import BookMeetingVideo from '../../components/BookMeeting/BookMeetingVideo';
import BookMeetingAlert from '../../components/BookMeeting/BookMeetingAlert';
import hashutils from '../../components/utils/hashutils';
import isWorkingHours from '../../components/utils/workingHours';

const BookMeeting = () => {
  const [days, setDays] = useState();
  const [timeDay, setTimeDay] = useState(null);
  const [meetingUid, setMeetingUid] = useState(null);
  const [updateTime, setUpdateTime] = useState(false);
  const [updateSlots, setUpdateSlots] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const [videoTitle, setVideoTitle] = useState(null);
  const [selectedTimeId, setSelectedTimeId] = useState(null);

  const [isUserTimeZoneChecked, setIsUserTimeZoneChecked] = useState(false);
  const {
    user,
    setUser,
    timeActive,
    setSelectedTime,
    getBookingSlots,
    timeList,
    setTimeList,
    timezone,
    setTimezone,
    translate
  } = useContext(GlobalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const search = location.search?.substring(1);

  const changeSelectedTime = async (time) => {
    const sendData = { datetime: time.id, meeting_uid: meetingUid, timezone: timezone.timeZone };

    if (user.createdBy) {
      sendData.createdBy = user.createdBy;
    }
    const result = await postBookingRescheduleAPI(sendData);
    if (result) {
      user.course = result.course.title;
      user.interviewReschedule = true;
      delete user.withPopup;

      navigate(`/${objectToUrl(user)}`);
      navigate(`/confirmed/${objectToUrl(user)}`);
      setSelectedTime(time.datetime);
    } else {
      navigate('/error/?type=2');
      setSelectedTime(time.datetime);
    }
    setTimeList(null);
    setSelectedTimeId(null);
  };

  const sendAnalytics = async () => {
    // eslint-disable-next-line
    dataLayer.push({
      event: 'meeting',
      enhanced_conversion_data: {
        first_name: await hashutils.hashSHA256(user.firstName),
        last_name: await hashutils.hashSHA256(user.lastName),
        email: await hashutils.hashSHA256(user.email)
      }
    });
  };

  const onClickTime = async (time) => {
    setSelectedTimeId(time.id);
    const isUserSelectedTimezone =
      format(new Date(), 'H:mm') === format(new Date(timezone.time), 'H:mm');

    user.regionTimezone = timezone.region;

    if (isUserSelectedTimezone) {
      user.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      user.timezone = timezone.timeZone;
    }

    user.timeSelected = time.datetime;
    if (meetingUid) {
      changeSelectedTime(time);
    } else {
      const result = await postBookingAPI({
        datetime: time.id,
        course_external_id: Number(user.externalProductId),
        order_uid: user.order,
        fields: user
      });
      if (!result.message) {
        await sendAnalytics();
        user.course = result.course.title;
        navigate(`/${objectToUrl(user)}&meeting_uid=${result.uid}`);
        navigate(`/confirmed/${objectToUrl(user)}&meeting_uid=${result.uid}`);
        setSelectedTime(time.datetime);
      } else {
        navigate('/error/?type=2');
        setSelectedTime(time.datetime);
      }
      setTimeList(null);
      setSelectedTimeId(null);
    }
  };

  const onClickDay = (day) => {
    if (days) {
      setDays((dayList) => {
        setIsShowButton(false);
        setTimeDay(dayList[day]);
        return dayList;
      });
    }
  };

  const onClickCallMeBack = async () => {
    const { workingHours, ...fields } = user;
    const result = await postCallMeBackAPI({
      course_external_id: Number(user.externalProductId),
      order_uid: user.order,
      fields
    });

    if (result.message) {
      navigate('/error');
      return;
    }

    await sendAnalytics();
    navigate('/thank-you/?from=call-now');
  };

  useEffect(() => {
    const userInfo = urlToObject(search);
    if (userInfo.meeting_uid) {
      setMeetingUid(userInfo.meeting_uid);
    }

    if (userInfo.redirectUri) {
      userInfo.redirectUri = decodeURIComponent(userInfo.redirectUri);
    }
    if (!userInfo.lastName || !userInfo.firstName || !userInfo.phone || !userInfo.email) {
      navigate('error');
      return;
    }
    if (userInfo.regionTimezone) {
      delete userInfo.regionTimezone;
    }
    if (userInfo.timeSelected) {
      delete userInfo.timeSelected;
    }
    if (userInfo.timezone) {
      delete userInfo.timezone;
    }

    if (userInfo.interviewReschedule) {
      delete userInfo.interviewReschedule;
    }
    if (userInfo.withVideo && !userInfo.meeting_uid) {
      setVideoTitle(userInfo.withVideo);
    }
    if (userInfo.workingHours) {
      userInfo.workingHours = JSON.parse(userInfo.workingHours);
    }
    setUser(userInfo);
  }, []);

  const updateBookingSlots = () => {
    setMeetingUid((uid) => {
      if (uid) {
        getBookingSlots({ meetingUid: uid, createdBy: user.createdBy });
      } else if (user.externalProductId && user.order) {
        getBookingSlots({
          id: user.externalProductId,
          order: user.order,
          createdBy: user.createdBy
        });
      }
      return uid;
    });
    setUpdateSlots(Date.now());
  };

  useEffect(() => {
    if (user) {
      if (!updateSlots) {
        updateBookingSlots();
      } else {
        const timeout = setTimeout(() => {
          updateBookingSlots();
        }, 60000);
        return () => clearTimeout(timeout);
      }
    }

    return () => {};
  }, [user, meetingUid, updateSlots]);

  const checkUserTimezone = () => {
    const findTimezone = [
      ...TIMEZONE_EUROPE,
      ...TIMEZONE_US_CANADA,
      ...TIMEZONE_OCEANIA,
      ...TIMEZONE_ASIA
    ].find((item) => format(new Date(), 'H:mm') === format(new Date(item.time), 'H:mm'));
    if (findTimezone) {
      setTimezone(findTimezone);
    } else {
      setTimezone(TIMEZONE_US_CANADA[0]);
    }
    setIsUserTimeZoneChecked(true);
  };

  useEffect(() => {
    setTimeout(() => {
      // update time for all timezones
      const date = new Date();

      [...TIMEZONE_US_CANADA, ...TIMEZONE_EUROPE, ...TIMEZONE_OCEANIA, ...TIMEZONE_ASIA].forEach(
        (item) => {
          // eslint-disable-next-line
          item.time = convertTimeZones(date, item.timeZone);
        }
      );
      setUpdateTime(!updateTime);
      if (!isUserTimeZoneChecked) {
        checkUserTimezone();
      }
    }, 1000);
  }, [updateTime]);

  useEffect(() => {
    if (timeActive) {
      const activeTimeCopy = JSON.parse(JSON.stringify(timeActive));
      if (timezone) {
        // change the time for the selected time zone
        activeTimeCopy.forEach((item, index) => {
          activeTimeCopy[index].datetime = convertTimeZones(
            new Date(item.datetime),
            timezone.timeZone
          );
        });
      }

      // return only works hours
      const filterTime = (time) => {
        const start = new Date(time);
        start.setHours(8, 0, 0, 0);
        const end = new Date(time);
        end.setHours(21, 0, 0, 0);

        return new Date(time) >= start && new Date(time) <= end;
      };

      const filteredTimeCopy = activeTimeCopy.filter((item) => filterTime(item.datetime));

      const result = {};
      filteredTimeCopy.forEach((item) => {
        const day = format(new Date(item.datetime), 'uu-MM-dd');
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(item);
      });
      if (!Object.keys(result)[0]) {
        // no slots
        navigate(`/thank-you/?${search}`);
      } else {
        setDays(result);

        if (timeDay && result[format(new Date(timeDay[0].datetime), 'uu-MM-dd')]) {
          setTimeDay(result[format(new Date(timeDay[0].datetime), 'uu-MM-dd')]);
        } else {
          setTimeDay(Object.values(result)[0]);
        }
      }
    }
  }, [timeActive, timezone]);

  const canCallMeBack = user?.workingHours && isWorkingHours(new Date(), user.workingHours);

  const isLoading = !timeList || !timezone;

  return (
    <>
      {isLoading && <Skeleton />}
      <div className={videoTitle ? styles.BookMeetingGlobal : ''} style={{ position: 'relative' }}>
        {videoTitle && !isLoading && (
          <div className={styles.BookMeetingGlobal__video}>
            <BookMeetingVideo videoTitle={videoTitle} />
          </div>
        )}
        <div
          className={videoTitle ? styles.BookMeetingGlobal__container : ''}
          style={{ alignItems: !isShowButton ? 'center' : 'start' }}>
          {videoTitle && !isLoading && (
            <div className={styles.BookMeetingGlobal__video__alert}>
              <BookMeetingAlert />
            </div>
          )}
          <div
            className={`${styles.BookMeeting} ${isLoading ? styles.isLoading : ''} ${
              meetingUid ? styles.reschedule : ''
            }`}>
            <h1>
              {meetingUid ? 'Reschedule your consultation' : translate.TITLE_IN_ENROLMENT_PAGE}
            </h1>
            <div className={styles.BookMeeting__alert}>
              <BookMeetingAlert />
            </div>
            <p className={styles.BookMeeting__info}>
              {/* Pick the most convenient time for a 10-min consultation with your Learning Advisor to
              discuss course outcomes, projects and logistics. */}
              {translate.DESCRIPTION_IN_ENROLMENT_PAGE}
            </p>
            {canCallMeBack && (
              <button
                type="button"
                className={styles.BookMeeting__callMeNow}
                onClick={onClickCallMeBack}>
                Call me now
              </button>
            )}
            <BookMeetingDateList listDays={days} onClickDay={onClickDay} />
            <BookMeetingSelect selectedTimezone={timezone} setSelectedTimezone={setTimezone} />
            <BookMeetingTimeList
              timeDay={timeDay}
              onClickTime={onClickTime}
              isShowButton={isShowButton}
              setIsShowButton={setIsShowButton}
              selectedTimeId={selectedTimeId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookMeeting;
