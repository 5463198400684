import { useContext } from 'react';
import styles from './BookMeetingAlert.module.scss';
import { GlobalContext } from '../../context/GlobalContext';

const BookMeetingAlert = () => {
  const { translate } = useContext(GlobalContext);
  return (
    <div className={styles.bookMeetingAlert}>
      <div className={styles.bookMeetingAlert__elvtrTheme}>
        {translate.WITH_EXCLAMATION_MARK_IN_ALERT && <p>!</p>}
        <span>{translate.DESCRIPTION_IN_ALERT}</span>
      </div>
      <div className={styles.bookMeetingAlert__slilliumTheme}>
        <span>
          <span>Important!</span> <br /> Please note your consultation is not finalised yet.
        </span>
      </div>
    </div>
  );
};

export default BookMeetingAlert;
