import styles from './Skeleton.module.scss';

const Skeleton = () => {
  return (
    <div className={styles.centralMap}>
      <div className={`${styles.headSkeleton} ${styles.gradient}`} />
      <div className={`${styles.warningSkeleton} ${styles.gradient}`} />
      <div className={`${styles.textSkeleton} ${styles.gradient}`} />

      <div className={styles.cardBox}>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <div key={item} className={`${styles.cardSkeleton} ${styles.gradient}`} />
        ))}
      </div>
      <div className={`${styles.standardTimeSkeleton} ${styles.gradient}`} />
      <div className={styles.timeCardSkeleton}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <div key={item} className={`${styles.timingSkeleton} ${styles.gradient}`} />
        ))}
      </div>
      <div className={`${styles.moreSlotsSkeleton} ${styles.gradient}`} />
    </div>
  );
};

export default Skeleton;
