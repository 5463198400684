const TimeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.22629 0.0771484C4.05046 0.0771484 0.668945 3.59827 0.668945 7.9368C0.668945 12.2753 4.05046 15.7964 8.22629 15.7964C12.4097 15.7964 15.7988 12.2753 15.7988 7.9368C15.7988 3.59827 12.4097 0.0771484 8.22629 0.0771484ZM11.4489 11.1986L7.47737 8.72276V4.00697H8.6121V8.13329L12.0163 10.2318L11.4489 11.1986Z"
        fill="#443B5C"
      />
    </svg>
  );
};

export default TimeIcon;
