import { useLocation } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './InterviewConfirmed.module.scss';
import InterviewConfirmedContacts from '../../components/InterviewConfirmed/InterviewConfirmedContacts';
import phoneIcon from '../../assets/images/phone1.png';

import ButtonError from '../../components/UI/ButtonError';
import ArrowIcon from '../../components/Icons/ArrowIcon';
import { urlToObject } from '../../components/utils/url';
import InterviewConfirmedInfo from '../../components/InterviewConfirmed/InterviewConfirmedInfo';
import { GlobalContext } from '../../context/GlobalContext';
import Loader from '../../components/Loader';
import InterviewConfirmedModal from '../../components/InterviewConfirmed/InterviewConfirmedModal';

const InterviewConfirmed = () => {
  const [type, setType] = useState(false);
  const [redirectUri, setRedirectUri] = useState(null);
  const [user, setUser] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);

  const { ownerData, translate, environmentConfig } = useContext(GlobalContext);

  const location = useLocation();
  const search = location.search?.substring(1);

  const onClickBtn = () => {
    if (!type) {
      window.location.href = redirectUri;
    } else if (process.env.REACT_APP_DESIGN === 'Skillium') {
      window.location.href = 'https://skillium.org/';
    } else {
      window.location.href = environmentConfig.URL_ABOUT_EVLTR;
    }
  };

  useEffect(() => {
    const userInfo = urlToObject(search);

    if (userInfo.withPopup) {
      setIsShowModal(true);
    }
    if (userInfo.redirectUri) {
      let url = userInfo.redirectUri;
      if (userInfo.theme) {
        url += `&theme=${userInfo.theme}`;
      }
      setRedirectUri(url);
    } else {
      setType(true);
    }

    if (userInfo.interviewReschedule) {
      setShowAlert(false);
    }

    setUser(userInfo);
  }, []);

  if (!user) {
    return null;
  }

  if (!ownerData && showAlert) {
    return <Loader />;
  }

  return (
    <>
      {isShowModal && (
        <InterviewConfirmedModal
          data={user}
          rescheduleLink={`/?${search}`}
          closeModal={() => setIsShowModal(false)}
        />
      )}
      <div className={styles.InterviewConfirmed}>
        <div className={styles.InterviewConfirmed__Text}>
          <h1>Consultation confirmed</h1>
          <p>
            <img src={phoneIcon} alt="phone" />
            {translate.TITLE_IN_CONSULTATION_PAGE}
          </p>
        </div>
        <InterviewConfirmedContacts
          course={user.course}
          date={user.timeSelected}
          tel={user.phone}
          email={user.email}
          timezone={user.regionTimezone}
        />
        {user.is_show_reschedule_btn && (
          <Link to={`/?${search}`}>{translate.RESCHEDULE_BUTTON}</Link>
        )}

        <InterviewConfirmedInfo type={type} showAlert={showAlert} email={ownerData?.email || ''} />
        <div className={styles.InterviewConfirmed__btn}>
          <ButtonError onClick={onClickBtn}>
            Continue
            <ArrowIcon />
          </ButtonError>
        </div>
      </div>
    </>
  );
};

export default InterviewConfirmed;
