const hashSHA256 = async (str) => {
  // Encode the string as a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  // Hash the data using SHA-256
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export default { hashSHA256 };
