const EmailIcon = () => {
  return (
    <svg
      className="elvtrIcon"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2858 0.93335H2.18193C1.346 0.93335 0.67651 1.63679 0.67651 2.50528L0.668945 11.9369C0.668945 12.8054 1.346 13.5088 2.18193 13.5088H14.2858C15.1217 13.5088 15.7988 12.8054 15.7988 11.9369V2.50528C15.7988 1.63679 15.1217 0.93335 14.2858 0.93335ZM14.2858 4.07721L8.23386 8.00703L2.18193 4.07721V2.50528L8.23386 6.4351L14.2858 2.50528V4.07721Z"
        fill="#443B5C"
      />
    </svg>
  );
};

export default EmailIcon;
