const ENVIRONMENT_CONFIG = {
  'Europe/London': {
    URL_ABOUT_EVLTR: 'https://uk.elvtr.com/aboutelvtr',
    URL_MAIN_ELVTR: 'https://uk.elvtr.com/'
  },

  DEFAULT_ENVIRONMENT: {
    URL_ABOUT_EVLTR: 'https://elvtr.com/aboutelvtr',
    URL_MAIN_ELVTR: 'https://elvtr.com/'
  }
};

export default ENVIRONMENT_CONFIG;
