import { format } from 'date-fns';
import styles from './BookMeetingTime.module.scss';
import Loader from '../UI/Loader';

const BookMeetingTime = ({ title, onClick, isLoading }) => {
  return (
    <button type="button" onClick={onClick} className={styles.BookMeetingTime} disabled={isLoading}>
      {isLoading ? <Loader /> : <h3>{format(new Date(title), 'hh:mm a')}</h3>}
    </button>
  );
};

export default BookMeetingTime;
