const CalendarIcon = () => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0162 9.29478H7.23372V13.2246H11.0162V9.29478ZM10.2597 0.64917V2.2211H4.20775V0.64917H2.69477V2.2211H1.93828C1.10235 2.2211 0.432858 2.92454 0.432858 3.79303L0.425293 14.7965C0.425293 15.665 1.10235 16.3685 1.93828 16.3685H12.5292C13.3651 16.3685 14.0421 15.665 14.0421 14.7965V3.79303C14.0421 2.92454 13.3651 2.2211 12.5292 2.2211H11.7727V0.64917H10.2597ZM12.5292 14.7965H1.93828V6.15092H12.5292V14.7965Z"
        fill="#443B5C"
      />
    </svg>
  );
};

export default CalendarIcon;
