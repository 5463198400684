const ArrowLeftIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1024 9.32388H2.54604" stroke="white" strokeWidth="3" strokeLinejoin="round" />
      <path
        d="M9.61719 16.3949L2.54612 9.32383L9.61719 2.25276"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
