import { createContext, useEffect, useMemo, useState } from 'react';
import { getBookingSlotsAPI, getGoogleEventOwnerAPI } from '../api/api';
import { objectToUrl } from '../components/utils/url';
import FORMATS from '../constants/market';
import TRANSLATE from '../constants/translate';
import ENVIRONMENT_CONFIG from '../constants/environmentConfig';

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [timeList, setTimeList] = useState(null);
  const [timeActive, setTimeActive] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [ownerData, setOwnerData] = useState(null);
  const [marketFormat, setMarketFormat] = useState(null);
  const [translate, setTranslate] = useState(null);
  const [environmentConfig, setEnvironmentConfig] = useState(null);

  const createOriginalTime = (time) => {
    const timeActiveCopy = JSON.parse(JSON.stringify(time));
    time.forEach((item, index) => {
      timeActiveCopy[index].id = item.datetime;
    });
    return timeActiveCopy;
  };

  const getBookingSlots = async (parameter) => {
    try {
      await getBookingSlotsAPI(parameter).then((value) => {
        if (!value.slots) {
          window.location = `/thank-you/${objectToUrl(user)}`;
        } else {
          const activeTime = value.slots.filter((item) => item.is_active);
          setTimeActive(createOriginalTime(activeTime));
          setTimeList(value);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const market = process.env.REACT_APP_MARKET;
    setMarketFormat(FORMATS[market] || FORMATS.DEFAULT_FORMATS);
    setTranslate(TRANSLATE[market] || TRANSLATE.DEFAULT_TRANSLATE);
    setEnvironmentConfig(ENVIRONMENT_CONFIG[market] || ENVIRONMENT_CONFIG.DEFAULT_ENVIRONMENT);

    const getGoogleEventOwner = async () => {
      try {
        const data = await getGoogleEventOwnerAPI();
        setOwnerData(data);
      } catch (err) {
        console.log(err);
      }
    };
    getGoogleEventOwner();
  }, []);

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      timeActive,
      timeList,
      selectedTime,
      setSelectedTime,
      getBookingSlots,
      setTimezone,
      setTimeList,
      timezone,
      ownerData,
      marketFormat,
      translate,
      environmentConfig
    }),
    [
      timeActive,
      selectedTime,
      user,
      timezone,
      timeList,
      setTimeList,
      ownerData,
      marketFormat,
      translate,
      environmentConfig
    ]
  );

  if (!marketFormat) {
    return null;
  }
  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
