import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useContext, useMemo } from 'react';

import RescheduleIcon from '../Icons/RescheduleIcon';
import styles from './InterviewConfirmedModal.module.scss';
import { GlobalContext } from '../../context/GlobalContext';
import helpers from '../utils/helpers';
import ArrowIcon from '../Icons/ArrowIcon';
import ButtonError from '../UI/ButtonError';

const InterviewConfirmedModal = ({ data, rescheduleLink, closeModal }) => {
  const { translate } = useContext(GlobalContext);

  const timezoneShort = useMemo(
    () => helpers.getTimezoneAttribute(data.regionTimezone, translate),
    [data, translate]
  );

  return (
    <div className={styles.confirmedModal}>
      <div className={styles.confirmedModal__content}>
        <div className={styles.confirmedModal__content__header}>
          🎁 &nbsp;A bonus insight from our course instructor - {data.instructorName.split('-')[0]}
        </div>
        <div className={styles.confirmedModal__content__alert}>
          You’ll receive an extra sneak peek into the course after you complete the pre-enrolment
          interview!
        </div>
        <div className={styles.confirmedModal__content__instructor}>
          <div className={styles.confirmedModal__content__instructor__photo}>
            {data.instructorPhoto && <img src={data.instructorPhoto} alt="instructor" />}
          </div>

          <div className={styles.confirmedModal__content__instructor__info}>
            {data.instructorName && (
              <h3
                dangerouslySetInnerHTML={{ __html: data.instructorName.replace(/-/g, ' <br>') }}
              />
            )}
            <p>Instructor for a {data.course} course</p>
            {data.instructorSign && (
              <img
                className={styles.confirmedModal__content__instructor__info__sign}
                src={data.instructorSign}
                alt="sign"
              />
            )}
          </div>
        </div>

        <div className={styles.confirmedModal__content__footer}>
          <p>
            One of my Learning Advisors from ELVTR will call you on{' '}
            {format(new Date(data.timeSelected), 'EEEE, do MMMM')} at
            {format(new Date(data.timeSelected), ' hh:mm a ')}
            {timezoneShort} on {data.phone} to go over the course details and prerequisites!
          </p>

          <ButtonError onClick={closeModal}>
            Continue
            <ArrowIcon />
          </ButtonError>
          <div className={styles.confirmedModal__content__footer__downBlock}>
            <span>Need to pick another time?</span>
            <div className={styles.confirmedModal__content__footer__downBlock__rescheduleBlock}>
              <RescheduleIcon /> <Link to={rescheduleLink}>Reschedule</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewConfirmedModal;
