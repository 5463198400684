import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import BookMeeting from './pages/BookMeeting/BookMeeting';
import ErrorPage from './pages/error/ErrorPage';
import InterviewConfirmed from './pages/InterviewConfirmed/InterviewConfirmed';
import ThankYou from './pages/thankYou/ThankYou';
import Reschedule from './pages/reschedule/Reschedule';
import BookingContact from './pages/BookingContact/BookingContact';

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_DESIGN === 'Skillium') {
      document.body.classList.add('skilliumDesign');
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BookMeeting />} />
        <Route path="/confirmed" element={<InterviewConfirmed />} />
        <Route path="/reschedule/:meeting_uid" element={<Reschedule />} />
        <Route path="/cancel-the-meeting" element={<Reschedule />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/booking/contact" element={<BookingContact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
