const TRANSLATE = {
  'Europe/London': {
    RESCHEDULE_BUTTON: 'Need to Reschedule?',
    TITLE_IN_CONSULTATION_PAGE: 'Your Learning Advisor will call you on:',
    DESCRIPTION_IN_ENROLMENT_PAGE:
      'Pick the most convenient time for a 10-minute consultation with your Learning Advisor to discuss course outcomes, projects and logistics.',
    TITLE_IN_ENROLMENT_PAGE: 'Enrolment consultation',
    DESCRIPTION_IN_ALERT: 'Please note your consultation is not finalised yet',
    WITH_EXCLAMATION_MARK_IN_ALERT: false,
    DESCRIPTION_IN_THANKS_PAGE: 'Click Continue to tell us more about your professional needs',
    BRITISH_TIMEZONE_ABBREVIATION: 'BST'
  },

  DEFAULT_TRANSLATE: {
    RESCHEDULE_BUTTON: 'Reschedule',
    TITLE_IN_CONSULTATION_PAGE: 'At the booked time your Learning Advisor will call you.',
    DESCRIPTION_IN_ENROLMENT_PAGE:
      'Pick the most convenient time for a 10-min consultation with your Learning Advisor to discuss course outcomes, projects and logistics.',
    TITLE_IN_ENROLMENT_PAGE: 'Enrollment consultation',
    DESCRIPTION_IN_ALERT: 'Please note your consultation is not finalised yet',
    WITH_EXCLAMATION_MARK_IN_ALERT: true,
    DESCRIPTION_IN_THANKS_PAGE:
      'Please continue to share your professional needs so your Learning Advisor can prepare for your consultation.',
    BRITISH_TIMEZONE_ABBREVIATION: 'WEST'
  }
};

export default TRANSLATE;
