import { useMemo, useState } from 'react';
import { addDays, format } from 'date-fns';
import BookMeetingDate from './BookMeetingDate';
import styles from './BookMeetingDateList.module.scss';

const BookMeetingDateList = ({ listDays, onClickDay }) => {
  const [activeDayIndex, setActiveDayIndex] = useState(-1);
  const [activeDay, setActiveDay] = useState(null);

  const onClickDate = (date, index) => {
    setActiveDayIndex(index);
    onClickDay(format(date, 'uuuu-MM-dd'));
    setActiveDay(format(date, 'uuuu-MM-dd'));
  };

  if (activeDay && !listDays[activeDay]) {
    setActiveDayIndex(-1);
    setActiveDay(null);
  }

  const days = useMemo(() => {
    const output = [];
    const newDay = new Date();
    output.push(newDay);
    for (let i = 1; i < 6; i += 1) {
      output.push(addDays(newDay, i));
    }
    return output;
  }, []);

  return (
    <div className={styles.BookMeetingDateList}>
      {listDays &&
        days.map((day, index) => {
          if (Object.keys(listDays)[0] === format(day, 'uuuu-MM-dd') && activeDayIndex === -1) {
            setActiveDayIndex(index);
            setActiveDay(Object.keys(listDays)[0]);
          }
          return (
            <BookMeetingDate
              key={day.getDate()}
              date={day}
              disabled={Object.prototype.hasOwnProperty.call(listDays, format(day, 'uuuu-MM-dd'))}
              active={activeDayIndex === index}
              index={index}
              onClick={onClickDate}
            />
          );
        })}
    </div>
  );
};

export default BookMeetingDateList;
