const SkilliumEmailIcon = () => (
  <svg
    className="skilliumIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none">
    <g clipPath="url(#clip0_2586_2105)">
      <path
        d="M18.6458 8.81442V16.8334H3.5208V8.81167L0.770798 5.60999V19.5834H21.3958V5.61205L18.6458 8.81442Z"
        fill="#191919"
      />
      <path
        d="M18.113 3.08334L11.0847 11.2831L6.41378 5.83334H13.9453L16.3021 3.08334H4.05703H3.80541H0.413284L11.0847 15.5085L21.7547 3.08334H18.113Z"
        fill="#191919"
      />
    </g>
    <defs>
      <clipPath id="clip0_2586_2105">
        <rect width="22" height="22" fill="white" transform="translate(0.0833359 0.333336)" />
      </clipPath>
    </defs>
  </svg>
);

export default SkilliumEmailIcon;
