import { memo, useContext, useMemo } from 'react';
import styles from './InterviewConfirmedInfo.module.scss';
import { GlobalContext } from '../../context/GlobalContext';

const InterviewConfirmedInfo = ({ type, showAlert, email }) => {
  const { translate } = useContext(GlobalContext);
  const alertValue = useMemo(() => {
    if (process.env.REACT_APP_MARKET === 'Europe/London') {
      return (
        <div className={styles.interviewConfirmedInfo__alert}>
          <p>
            <span>!</span> A calendar invitation has been sent to you from {email}.
          </p>
          <p>Please check your email and confirm that you recognise the sender.</p>
          <p>Just add the event to your calendar and let us know if you need to reschedule.</p>
          <p>We look forward to meeting with you soon</p>
        </div>
      );
    }

    return (
      <div className={styles.interviewConfirmedInfo__alert}>
        <p>
          <span>!</span> A calendar invitation for your upcoming consultation has been sent to you
          from {email}.
        </p>
        <p>
          To avoid any scheduling conflict, kindly check your email, confirm that you recognize the
          sender, and add the event to your calendar.
        </p>
        <p>Thank you for your prompt attention, and we look forward to meeting with you soon.</p>
      </div>
    );
  }, [email]);

  if (showAlert) {
    return <div className={styles.interviewConfirmedInfo}>{alertValue}</div>;
  }

  if (type) {
    return (
      <div className={styles.interviewConfirmedInfo}>
        <div className={styles.interviewConfirmedInfo__block} />
      </div>
    );
  }
  return (
    <div className={styles.interviewConfirmedInfo}>
      <div className={styles.interviewConfirmedInfo__text}>
        <span>{translate.DESCRIPTION_IN_THANKS_PAGE}</span>
      </div>
    </div>
  );
};

export default memo(InterviewConfirmedInfo);
