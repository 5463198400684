import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { postBookingLinkEmailAPI } from '../../api/api';
import Skeleton from '../../components/Skeleton/Skeleton';
import { urlToObject } from '../../components/utils/url';

const BookingContact = () => {
  const search = window.location.search.substring(1);
  const navigate = useNavigate();

  const extractQueryString = (url) => {
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex !== -1) {
      return url.slice(queryStringIndex);
    }
    return '';
  };

  useEffect(() => {
    const postBookingLinkEmail = async () => {
      try {
        const result = await postBookingLinkEmailAPI(urlToObject(decodeURIComponent(search)));
        navigate(`/${extractQueryString(result.booking_link)}`);
      } catch (e) {
        navigate('/error');
      }
    };
    if (search) {
      postBookingLinkEmail();
    }
  }, [search]);

  return <Skeleton />;
};

export default BookingContact;
