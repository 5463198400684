const StudentIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_242_6259)">
        <path
          d="M3.96118 9.11687V11.6384L8.37377 14.0464L12.7864 11.6384V9.11687L8.37377 11.5249L3.96118 9.11687ZM8.37377 2.69971L1.4397 6.48193L8.37377 10.2641L14.0471 7.16903V11.5249H15.3078V6.48193L8.37377 2.69971Z"
          fill="#443B5C"
        />
      </g>
      <defs>
        <clipPath id="clip0_242_6259">
          <rect
            width="15.1289"
            height="15.1289"
            fill="white"
            transform="translate(0.809326 0.808594)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StudentIcon;
