const LogoIcon = () => {
  return (
    <svg width="76" height="40" viewBox="0 0 76 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7H4.33333V33H0V7ZM65 7H69.3333V33H65V7ZM25.5572 26.529C23.2461 26.529 21.9056 25.2117 21.9056 23.0161V10.4668H25.4878V22.3228C25.4878 22.785 25.8114 23.0623 26.2967 23.0623H27.6834V26.529H25.5572ZM8.69112 20.7512C8.69112 17.2845 11.1871 14.6268 14.7462 14.6268C18.6058 14.6268 21.0787 17.7237 20.6165 21.9299H12.2965C12.5276 22.8081 13.2902 23.5939 14.6307 23.7094C15.5089 23.7788 16.4565 23.409 16.8262 22.8312H20.5471C19.8076 25.4659 17.4271 26.8757 14.6769 26.8757C10.7249 26.8757 8.69112 23.9868 8.69112 20.7512ZM17.0805 19.2721C16.7569 18.3014 15.8556 17.7699 14.7231 17.7699C13.5676 17.7699 12.6662 18.3477 12.3427 19.2721H17.0805ZM33.8266 22.2997L30.8453 14.9734H27.0088L31.8391 26.529H35.4213L40.2515 14.9734H36.6L33.8266 22.2997ZM45.5028 14.9734H50.0095V18.4401H45.5028V22.3228C45.5028 22.785 45.8263 23.0623 46.3117 23.0623H50.0095V26.529H45.6415C43.4228 26.529 41.9206 25.0961 41.9206 22.9005V18.4401H40.5339V14.9734H41.9206V10.4668H45.5028V14.9734ZM57.3693 14.9734C56.1906 14.9734 55.243 15.6668 54.9195 16.7068H54.827V14.9734H51.4297V26.529H55.0119V20.6819C55.0119 19.457 55.9133 18.5557 57.1382 18.5557H59.9346V14.9734H57.3693Z"
        fill="black"
      />
    </svg>
  );
};

export default LogoIcon;
