const getTimezoneAttribute = (timezone, translate) => {
  switch (timezone) {
    case 'Pacific Time - US & Canada':
      return 'PDT';
    case 'Mountain Time - US & Canada':
      return 'MDT';
    case 'Central Time - US & Canada':
      return 'CDT';
    case 'Eastern Time - US & Canada':
      return 'EDT';
    case 'Alaska Time - US':
      return 'AKDT';
    case 'Newfoundland Time - Canada':
      return 'NDT';
    case 'Hawaii Time - US':
      return 'HST';
    case 'Central European Time':
      return 'CEST';
    case 'Eastern European Time':
      return 'EEST';
    case 'UK, Ireland, Lisbon Time':
      return translate.BRITISH_TIMEZONE_ABBREVIATION;
    case 'New Zealand Daylight Time':
      return 'NZDT';
    case 'Hong Kong Time':
      return 'HKT';
    case 'Australian Western Standard Time':
      return 'AWST';
    case 'Australian Central Daylight Time':
      return 'ACST';
    case 'Australian Eastern Standard Time':
      return 'AEST';
    default:
      return '';
  }
};

export default { getTimezoneAttribute };
