export const objectToUrl = (obj) => {
  const params = Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${params}`;
};

export const urlToObject = (url) => {
  const searchParams = new URLSearchParams(url);
  const obj = {};
  searchParams.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};
