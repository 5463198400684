const FORMATS = {
  'Europe/London': {
    TIME_FORMAT_CONFORMATION_PAGE: 'EEEE do MMMM',
    RESCHEDULE_BUTTON: 'Need to Reschedule'
  },
  DEFAULT_FORMATS: {
    TIME_FORMAT_CONFORMATION_PAGE: 'EEEE, MMM do',
    RESCHEDULE_BUTTON: 'Reschedule'
  }
};

export default FORMATS;
