import { format } from 'date-fns';
import { useState } from 'react';
import {
  TIMEZONE_ASIA,
  TIMEZONE_EUROPE,
  TIMEZONE_OCEANIA,
  TIMEZONE_US_CANADA
} from '../../constants/timezone';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import styles from './BookMeetingDropdown.module.scss';
import BookMeetingDropdownItem from './BookMeetingDropdownItem';

const MARKET = process.env.REACT_APP_MARKET;

const BookMeetingDropdown = ({ setSelectedTimezone, selectedTimezone }) => {
  const [isZonesSelectOpen, setIsZonesSelectOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleButtonClick = (timezone) => {
    setSelectedTimezone(timezone);
    setIsZonesSelectOpen(false);
    setIsActive(false);
  };

  const toggleActive = () => {
    setIsActive(!isActive);
    setIsZonesSelectOpen(!isZonesSelectOpen);
  };

  return (
    <div className={`${styles.BookMeetingDropdown} ${isActive ? styles.active : ''}`}>
      <button className={styles.BookMeetingDropdown__button} type="button" onClick={toggleActive}>
        {selectedTimezone
          ? `${selectedTimezone.region} (${format(new Date(selectedTimezone.time), 'hh:mm a')})`
          : 'Pacific Standart Time'}
        <ArrowDownIcon />
      </button>
      {isZonesSelectOpen && (
        <div className={styles.BookMeetingDropdown__list}>
          {MARKET === 'Europe/London' ? (
            <>
              <h2>EUROPE</h2>
              {[...TIMEZONE_EUROPE].reverse().map((item) => (
                <BookMeetingDropdownItem
                  key={item.id}
                  value={item.region}
                  time={item.time}
                  onClick={() => handleButtonClick(item)}
                />
              ))}
              <h2>US/CANADA</h2>
              {TIMEZONE_US_CANADA.map((item) => (
                <BookMeetingDropdownItem
                  key={item.id}
                  value={item.region}
                  time={item.time}
                  onClick={() => handleButtonClick(item)}
                />
              ))}
            </>
          ) : (
            <>
              <h2>US/CANADA</h2>
              {TIMEZONE_US_CANADA.map((item) => (
                <BookMeetingDropdownItem
                  key={item.id}
                  value={item.region}
                  time={item.time}
                  onClick={() => handleButtonClick(item)}
                />
              ))}
              <h2>EUROPE</h2>
              {TIMEZONE_EUROPE.map((item) => (
                <BookMeetingDropdownItem
                  key={item.id}
                  value={item.region}
                  time={item.time}
                  onClick={() => handleButtonClick(item)}
                />
              ))}
            </>
          )}
          <h2>OCEANIA</h2>
          {TIMEZONE_OCEANIA.map((item) => (
            <BookMeetingDropdownItem
              key={item.id}
              value={item.region}
              time={item.time}
              onClick={() => handleButtonClick(item)}
            />
          ))}
          <h2>ASIA</h2>
          {TIMEZONE_ASIA.map((item) => (
            <BookMeetingDropdownItem
              key={item.id}
              value={item.region}
              time={item.time}
              onClick={() => handleButtonClick(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BookMeetingDropdown;
