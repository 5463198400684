const RescheduleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M16.75 4.44043V2.88043C16.75 2.47043 16.41 2.13043 16 2.13043C15.59 2.13043 15.25 2.47043 15.25 2.88043V4.38043H8.74998V2.88043C8.74998 2.47043 8.40998 2.13043 7.99998 2.13043C7.58998 2.13043 7.24998 2.47043 7.24998 2.88043V4.44043C4.54998 4.69043 3.23999 6.30043 3.03999 8.69043C3.01999 8.98043 3.25999 9.22043 3.53999 9.22043H20.46C20.75 9.22043 20.99 8.97043 20.96 8.69043C20.76 6.30043 19.45 4.69043 16.75 4.44043Z"
      fill="#212121"
    />
    <path
      opacity="0.4"
      d="M20 10.7204C20.55 10.7204 21 11.1704 21 11.7204V17.8804C21 20.8804 19.5 22.8804 16 22.8804H8C4.5 22.8804 3 20.8804 3 17.8804V11.7204C3 11.1704 3.45 10.7204 4 10.7204H20Z"
      fill="#212121"
    />
    <path
      d="M14.8399 15.8704L14.3399 16.3804H14.3299L11.2999 19.4104C11.1699 19.5404 10.8999 19.6804 10.7099 19.7004L9.35993 19.9004C8.86993 19.9704 8.52995 19.6204 8.59995 19.1404L8.78996 17.7804C8.81996 17.5904 8.94993 17.3304 9.07993 17.1904L12.1199 14.1604L12.6199 13.6504C12.9499 13.3204 13.3199 13.0804 13.7199 13.0804C14.0599 13.0804 14.4299 13.2404 14.8399 13.6504C15.7399 14.5504 15.4499 15.2604 14.8399 15.8704Z"
      fill="#212121"
    />
  </svg>
);

export default RescheduleIcon;
