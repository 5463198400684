import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../components/Icons/ArrowLeftIcon';
import LogoIcon from '../../components/Icons/LogoIcon';
import ButtonError from '../../components/UI/ButtonError';
import styles from './ErrorPage.module.scss';
import { GlobalContext } from '../../context/GlobalContext';

const ErrorPage = () => {
  const [type, setType] = useState(1);

  const { environmentConfig } = useContext(GlobalContext);

  const navigate = useNavigate();

  const params = new URL(document.location).searchParams;

  useEffect(() => {
    if (params.get('type') === '2') {
      setType(2);
    }
  }, [params]);

  const onClickBtn = () => {
    if (type === 2) {
      navigate(-1);
    } else if (process.env.REACT_APP_DESIGN === 'Skillium') {
      window.location.href = 'https://skillium.org/';
    } else {
      window.location.href = environmentConfig.URL_MAIN_ELVTR;
    }
  };

  if (type === 2) {
    return (
      <div className={styles.errorPage}>
        <div className={styles.errorPage__logo}>
          <LogoIcon />
        </div>
        <div className={styles.errorPage__info}>
          <h2>Sorry,</h2>
          <p style={{ fontSize: '24px' }}>the time you selected has been booked by another user</p>
          <span>Please choose another time</span>
          <div className={styles.errorPage__info__btn}>
            <ButtonError onClick={onClickBtn}>
              <>
                <ArrowLeftIcon /> Back
              </>
            </ButtonError>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorPage__logo}>
        <LogoIcon />
      </div>
      <div className={styles.errorPage__info}>
        <h2>Ooops...,</h2>
        <p>something went wrong</p>
        <div className={styles.errorPage__info__btn}>
          <ButtonError onClick={onClickBtn}>Main page</ButtonError>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
