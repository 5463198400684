import BookMeetingTime from './BookMeetingTime';
import styles from './BookMeetingTimeList.module.scss';

const BookMeetingTimeList = ({
  timeDay,
  onClickTime,
  isShowButton,
  setIsShowButton,
  selectedTimeId
}) => {
  return (
    <div className={styles.BookMeetingTimeList}>
      <div className={styles.BookMeetingTimeList__Block}>
        {selectedTimeId && <div className={styles.BookMeetingTimeList__Block__backdrop} />}
        {timeDay?.map((item, index) => {
          if (!isShowButton && index > 8) {
            return '';
          }
          return (
            <BookMeetingTime
              key={item.datetime}
              title={item.datetime}
              onClick={() => onClickTime(item)}
              isLoading={selectedTimeId === item.id}
            />
          );
        })}
      </div>
      {timeDay?.length > 9 && (
        <button
          className={`${styles.BookMeetingTimeList__Button} ${
            isShowButton && styles.BookMeetingTimeList__Button__Active
          }`}
          onClick={() => setIsShowButton(true)}
          type="button">
          Show more time slots
        </button>
      )}
    </div>
  );
};

export default BookMeetingTimeList;
