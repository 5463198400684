import React, { useCallback, useEffect, useRef, useState } from 'react';
import { postTrackingLogAPI } from '../../api/api';
import LOG_EVENTS from '../utils/logEvents';

const BookMeetingVideo = ({ videoTitle }) => {
  const videoRef = useRef(null);
  const [isAudio, setIsAudion] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);

  const postTrackingLog = useCallback(
    async (event) => {
      if (event === LOG_EVENTS.VIDEO_SHOWN && isShowVideo) {
        return;
      }
      if (event === LOG_EVENTS.VIDEO_UNMUTED && isAudio) {
        return;
      }
      try {
        await postTrackingLogAPI(event);
      } catch (err) {
        console.log(err);
      }
    },
    [isAudio, isShowVideo]
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!isShowVideo) {
      setIsShowVideo((prev) => {
        if (!prev) {
          postTrackingLog(LOG_EVENTS.VIDEO_SHOWN);
          return true;
        }
        return prev;
      });
    }

    const handleVolumeChange = () => {
      setIsAudion((prev) => {
        if (videoElement.volume > 0 && !prev) {
          postTrackingLog(LOG_EVENTS.VIDEO_UNMUTED);
          return true;
        }

        return prev;
      });
    };
    videoElement.addEventListener('volumechange', handleVolumeChange);

    return () => {
      videoElement.removeEventListener('volumechange', handleVolumeChange);
    };
  }, []);

  return (
    <video
      ref={videoRef}
      width="100%"
      src={`https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/MeetingService/${videoTitle}`}
      autoPlay
      muted
      controls
      playsInline
      controlsList="nofullscreen nodownload"
    />
  );
};

export default BookMeetingVideo;
