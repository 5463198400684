const TelephoneIcon = () => {
  return (
    <svg
      className="elvtrIcon"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.16757 6.63185C4.25692 8.85613 6.0082 10.6756 8.15285 11.8074L9.81713 10.0744C10.0252 9.85823 10.324 9.79535 10.585 9.88181C11.4322 10.1726 12.3438 10.3298 13.2856 10.3298C13.7055 10.3298 14.0421 10.6796 14.0421 11.1158V13.8667C14.0421 14.3029 13.7055 14.6526 13.2856 14.6526C6.18219 14.6526 0.425293 8.67142 0.425293 1.29121C0.425293 0.855003 0.765714 0.505249 1.18178 0.505249H3.8295C4.24936 0.505249 4.58599 0.855003 4.58599 1.29121C4.58599 2.26974 4.73729 3.21683 5.01719 4.09711C5.10041 4.36827 5.03989 4.67872 4.83185 4.89486L3.16757 6.63185Z"
        fill="#443B5C"
      />
    </svg>
  );
};

export default TelephoneIcon;
